import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MenuItem from "@material-ui/core/MenuItem";
import React, { useEffect, useState } from "react";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";
import { updateUserProfile } from "../../actions/auth";
import { LOADING_MESSAGE, SET_MESSAGE } from "../../actions/types";
import {
  deleteUserSelf,
  getUserDetail,
  stripeManage,
} from "../../services/user.service";
import { industries } from "../common/common.js";
import SigninImage from "../images/avatar.png";
import { useHistory } from "react-router-dom";
import Input from "antd/lib/input/Input";
import { Modal } from "antd";
import secureLocalStorage from "react-secure-storage";
let deleteConfirm = "";
const UpdateUserProfile = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.message);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    country: "",
    state: "",
    city: "",
    industry: "",
    company: "",
    isOtpEnabled: false,
    showTooltips: false,
    optIn: false,
  });

  const changeInputHandler = (e) => {
    const { name, value, checked, type } = e.target;
    setFormData({ ...formData, [name]: type === "checkbox" ? checked : value });
  };

  useEffect(() => {
    dispatch({ type: LOADING_MESSAGE, isLoading: true });
    getUserDetail()
      .then((data) => {
        if (data.status === 200) {
          const user = data?.data?.user;
          setFormData({ ...formData, ...user });
        }
        dispatch({ type: LOADING_MESSAGE, isLoading: false });
      })
      .catch((error) => {
        const message =
          error?.response?.data?.message || error?.message || error?.toString();
        dispatch({
          type: SET_MESSAGE,
          payload: { message: message, severity: "error", isOpen: true },
        });
      });
    sessionStorage.setItem("reloadMap", 1);
  }, []);

  const handleUpdate = (e) => {
    e.preventDefault();
    if (!formData.country || !formData.state) {
      dispatch({
        type: SET_MESSAGE,
        payload: {
          message: formData.country ? "Select state" : "Select country",
          severity: "error",
          isOpen: true,
        },
      });
      return;
    }
    dispatch({ type: LOADING_MESSAGE, isLoading: true });
    dispatch(updateUserProfile(formData));
  };

  return (
    <>
      <div className="MainLoginBg"></div>
      <div
        className="SigninWrapp"
        style={{ overflow: "auto", height: "calc(100vh - 155px)" }}
      >
        <div className="SigninTitle">
          <div className="inlineBox">
            <h2>Update Info</h2>
            {!secureLocalStorage.getItem("industry") && (
              <h4 style={{ color: "red" }}>
                Please complete your profile to access complete app.
              </h4>
            )}
          </div>
          <div className="inlineBox text-right">
            <img src={SigninImage} alt="" />
          </div>
        </div>
        <TabPanel value={0} index={0}>
          <ValidatorForm className="FormStyle" onSubmit={handleUpdate}>
            <div>
              <label>Email</label>
              <TextValidator
                disabled
                validators={["isEmail"]}
                errorMessages={["this field is required", "email is not valid"]}
                name="email"
                value={formData.email}
                onChange={changeInputHandler}
                type="text"
                className="form-control"
                placeholder="Email"
              />
            </div>
            <div>
              <label>First name</label>
              <TextValidator
                validators={["required"]}
                errorMessages={["this field is required"]}
                name="firstName"
                value={formData.firstName}
                onChange={changeInputHandler}
                type="text"
                className="form-control"
                placeholder="First name"
              />
            </div>
            <div>
              <label>Last name</label>
              <TextValidator
                validators={["required"]}
                errorMessages={["this field is required"]}
                name="lastName"
                value={formData.lastName}
                onChange={changeInputHandler}
                type="text"
                className="form-control"
                placeholder="Last name"
              />
            </div>

            <div>
              <label>Industry</label>
              <TextValidator
                id="standard-select-currency"
                select
                onChange={changeInputHandler}
                variant="standard"
                validators={["required"]}
                errorMessages={["This field is required."]}
                name="industry"
                value={formData.industry}
                className="form-control"
              >
                {industries.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextValidator>
            </div>
            <div>
              <label>Country</label>
              <CountryDropdown
                style={{ fontFamily: "revert" }}
                value={formData.country}
                className="form-control"
                defaultOptionLabel="Select Country"
                onChange={(value) =>
                  setFormData({ ...formData, country: value })
                }
              />
            </div>
            <div>
              <label>State</label>
              <RegionDropdown
                style={{ fontFamily: "revert" }}
                country={formData.country}
                value={formData.state}
                className="form-control"
                defaultOptionLabel="Select State"
                onChange={(value) => setFormData({ ...formData, state: value })}
              />
            </div>
            <div>
              <label>City</label>
              <TextValidator
                // validators={["required"]}
                // errorMessages={["This field is required."]}
                name="city"
                value={formData.city}
                onChange={changeInputHandler}
                type="text"
                className="form-control"
                placeholder="City"
              />
            </div>
            <div>
              <label>Company</label>
              <TextValidator
                validators={["required"]}
                errorMessages={["this field is required"]}
                name="company"
                value={formData.company}
                onChange={changeInputHandler}
                className="form-control"
                placeholder="Company name"
              />
            </div>
            {/* <div>
              <label>User Type</label>
              <TextValidator
                disabled
                name="userType"
                value={"Free"}
                onChange={changeInputHandler}
                className="form-control"
                placeholder="Company name"
              />
            </div> */}
            {/* {!secureLocalStorage.getItem("industry") ? ( */}
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    name="optIn"
                    checked={formData.optIn}
                    onChange={changeInputHandler}
                  />
                }
                label={
                  "I agree to receive other communications from METAGEO Inc.."
                }
              />
            </div>

            <>
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="isOtpEnabled"
                      checked={formData.isOtpEnabled}
                      onChange={changeInputHandler}
                    />
                  }
                  label={"Enable Two-Factor Authentication"}
                />
              </div>
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="showTooltips"
                      checked={formData.showTooltips}
                      onChange={changeInputHandler}
                    />
                  }
                  label={"Enable Tips"}
                />
              </div>
            </>
            <div>
              <Button
                type="submit"
                variant="contained"
                className="btn-block btnTheme"
              >
                Save
              </Button>
            </div>
            <div style={{ textAlign: "center", marginTop: "10px" }}>
              <Button
                style={{ width: "100%" }}
                onClick={() => {
                  history.push("/manage-plan");
                }}
                variant="contained"
                className="btn-block btnTheme"
              >
                Manage Subscriptions
              </Button>
            </div>
            <div style={{ textAlign: "center", marginTop: "10px" }}>
              <Button
                variant="contained"
                onClick={() => {
                  history.push("/update-password");
                }}
                className="btn-block btnTheme"
                style={{
                  width: "48%",
                  marginRight: "19px",
                }}
              >
                Change password
              </Button>
              <Button
                id="deleteButton"
                onClick={() => {
                  deleteConfirm = "";
                  Modal.confirm({
                    title:
                      "Are you sure you want to delete this account? Please confirm by typing “Delete my account” in the text box below.",
                    icon: <ExclamationCircleOutlined />,
                    okText: "Confirm",
                    okType: "danger",
                    content: (
                      <>
                        <Input
                          placeholder="Please confirm"
                          onChange={(e) => {
                            deleteConfirm = e.target.value;
                          }}
                        />
                      </>
                    ),
                    cancelText: "Cancel",
                    onOk: () => {
                      if (deleteConfirm.toLowerCase() !== "delete my account") {
                        dispatch({
                          type: SET_MESSAGE,
                          payload: {
                            message:
                              "Please confirm in the text box to proceed",
                            severity: "error",
                            isOpen: true,
                          },
                        });
                        document.getElementById("deleteButton").click();
                      } else {
                        dispatch({ type: LOADING_MESSAGE, isLoading: true });
                        deleteUserSelf(secureLocalStorage.getItem("useruuId"))
                          .then((res) => {
                            dispatch({
                              type: LOADING_MESSAGE,
                              isLoading: false,
                            });
                            secureLocalStorage.clear();
                            window.location.reload(true);
                          })
                          .catch(() => {
                            dispatch({
                              type: LOADING_MESSAGE,
                              isLoading: false,
                            });
                          });
                      }
                    },
                  });
                }}
                style={{
                  width: "48%",
                }}
                variant="contained"
                className="btn-block btnTheme"
              >
                Delete Account
              </Button>
            </div>
          </ValidatorForm>
        </TabPanel>
      </div>
      <div className="Overlay"></div>
      <Backdrop open={isLoading} style={{ zIndex: 99999999999, color: "#fff" }}>
        <CircularProgress />
      </Backdrop>
    </>
  );
};

function TabPanel(props) {
  const { children, value, index } = props;
  return <div>{value === index && <h1>{children}</h1>}</div>;
}

export default UpdateUserProfile;
