import React, { useEffect } from "react";
import { forwardRef } from "react";
import Backdrop from "@material-ui/core/Backdrop";
import AddBox from "@material-ui/icons/AddBox";
import { Link } from "react-router-dom";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import DoneOutlineIcon from "@material-ui/icons/DoneOutline";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import MaterialTable from "material-table";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getAllLogs } from "../../services/user.service";
import { DatePicker } from "antd";
import { IconButton, Input } from "@material-ui/core";

const { RangePicker } = DatePicker;
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  save: forwardRef((props, ref) => <DoneOutlineIcon {...props} ref={ref} />),

  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
function validateField(val) {
  return val != "";
}

const Support = () => {
  const { useState } = React;
  const columns = [
    {
      title: "User Id",
      field: "userId",
      editable: "never",
    },
    {
      title: "Email",
      render: (col) => (
        <Link to={`/user-profile?user=${col?.user?.email}`}>
          {col?.user?.email}
        </Link>
      ),
      field: "user.email",
      editable: "never",
    },
    {
      title: "Account Type",
      field: "actionType",
      editable: "never",
    },
    {
      title: "IP Address",
      field: "ipAddress",
      editable: "never",
    },
    {
      title: "Action",
      field: "action",
      editable: "never",
    },
    // {
    //   title: "Status",
    //   field: "status",
    //   lookup: {
    //     Pending: "Pending",
    //     "In Progress": "In Progress",
    //     Resolved: "Resolved",
    //   },
    // },
    {
      title: "Date",
      editable: "never",
      field: "createdAt",
      render: (rowData) => {
        return (
          rowData.createdAt.split("T")[0] +
          " " +
          rowData.createdAt.split("T")[1].substring(0, 8)
        );
      },
    },
  ];

  const [data, setData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [page, setPage] = useState(0);
  const [row, setRow] = useState(5);
  const [email, setEmail] = useState("");
  const [date1, setDate1] = useState("");
  const [date2, setDate2] = useState("");
  //   const [date, setDate] = useState({ start: "", end: "" });

  const [backDropOpen, setBackDropOpen] = React.useState(true);
  useEffect(() => {
    setBackDropOpen(true);
    getAllLogs(date1, date2, email, page + 1, row)
      .then((res) => {
        setTotalItems(res.data.paginationInfo.totalItems);
        setData(res.data.userLogs.rows);
        setBackDropOpen(false);
      })
      .catch(() => {
        setTotalItems(0);
        setData([]);
        setBackDropOpen(false);
      });
  }, []);
  return (
    <div className="margindiv usersTable">
      <MaterialTable
        page={page}
        onChangePage={(page, size) => {
          setPage(page);
          setBackDropOpen(true);
          getAllLogs(date1, date2, email, page + 1, size)
            .then((res) => {
              setTotalItems(res.data.paginationInfo.totalItems);
              setData(res.data.userLogs.rows);
              setBackDropOpen(false);
            })
            .catch(() => {
              setTotalItems(0);
              setData([]);
              setBackDropOpen(false);
            });
        }}
        onChangeRowsPerPage={(page, size) => {
          setRow(size);
          setPage(0);
          setBackDropOpen(true);
          getAllLogs(date1, date2, email, 0 + 1, size)
            .then((res) => {
              setTotalItems(res.data.paginationInfo.totalItems);
              setData(res.data.userLogs.rows);
              setBackDropOpen(false);
            })
            .catch(() => {
              setTotalItems(0);
              setData([]);
              setBackDropOpen(false);
            });
        }}
        // editable={{
        //   onRowUpdate: (newData, oldData) => {
        //     return new Promise((resolve, reject) => {
        //       updateIssue(newData.status, newData.id).then((res) => {
        //         const dataUpdate = [...data];
        //         const index = oldData.tableData.id;
        //         dataUpdate[index] = newData;
        //         setData([...dataUpdate]);

        //         resolve();
        //       });
        //     });
        //   },
        //   // onRowDelete: (oldData) =>
        //   //   new Promise((resolve, reject) => {
        //   //     deleteUser(oldData.uuid).then((res) => {
        //   //       const dataDelete = [...data];
        //   //       const index = oldData.tableData.id;
        //   //       dataDelete.splice(index, 1);
        //   //       setData([...dataDelete]);
        //   //       resolve();
        //   //     });
        //   //   }),
        // }}
        options={{
          exportButton: true,
          exportAllData: true,
          search: false,
        }}
        icons={tableIcons}
        title={
          <>
            <span style={{ marginRight: 50 }} className="MuiTypography-h6">
              User Logs
            </span>
            <RangePicker
              onChange={(a, b, c) => {
                // setDate({ start: b[0], end: b[1] });
                setBackDropOpen(true);
                setDate1(b[0]);
                setDate2(b[1]);
                setPage(0);
                getAllLogs(b[0], b[1], email, 0 + 1, row)
                  .then((res) => {
                    setTotalItems(res.data.paginationInfo.totalItems);
                    setData(res.data.userLogs.rows);
                    setBackDropOpen(false);
                  })
                  .catch(() => {
                    setTotalItems(0);
                    setData([]);
                    setBackDropOpen(false);
                  });
              }}
            />

            <Input
              style={{ marginLeft: 50, width: 200 }}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  setBackDropOpen(true);
                  setPage(0);
                  getAllLogs(date1, date2, email, 0 + 1, row)
                    .then((res) => {
                      setTotalItems(res.data.paginationInfo.totalItems);
                      setData(res.data.userLogs.rows);
                      setBackDropOpen(false);
                    })
                    .catch(() => {
                      setTotalItems(0);
                      setData([]);
                      setBackDropOpen(false);
                    });
                }
              }}
              placeholder="Search Email"
              inputProps={{ "aria-label": "search google maps" }}
            />
            <IconButton
              onClick={() => {
                getAllLogs(date1, date2, email, page + 1, row)
                  .then((res) => {
                    setTotalItems(res.data.paginationInfo.totalItems);
                    setData(res.data.userLogs.rows);
                    setBackDropOpen(false);
                  })
                  .catch(() => {
                    setTotalItems(0);
                    setData([]);
                    setBackDropOpen(false);
                  });
              }}
              type="button"
              sx={{ p: "10px" }}
              aria-label="search"
            >
              <Search />
            </IconButton>
          </>
        }
        columns={columns}
        data={data}
        totalCount={totalItems}
      />
      <Backdrop
        open={backDropOpen}
        style={{ zIndex: 99999999999, color: "#fff" }}
      >
        <CircularProgress />
      </Backdrop>
    </div>
  );
};

export default Support;
