import React from 'react';
import VideoHome from './VideoHome';
import MainBG from './MainBG';
import Footer from './Footer.js';
import CopyRight from './CopyRight.js';


export const Home = () => {
    return (
        <> 
        <VideoHome />
        <MainBG />
        <Footer />
        <CopyRight />
        </>
    )
}

export default Home