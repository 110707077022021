import Button from "@material-ui/core/Button";
import React, { useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";
import { updatePassword } from "../../actions/auth";
import { LOADING_MESSAGE } from "../../actions/types";
import SigninImage from "../images/avatar.png";

let repPass = "";

const UpdatePassword = () => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.message);
  const [formData, setFormData] = useState({
    oldPassword: "",
    password: "",
    repeatPassword: ""
  });

  const changeInputHandler = (e) => {
    const { name, value, checked, type } = e.target;
    if (name === "password") {
      repPass = value;
    }
    setFormData({ ...formData, [name]: type === "checkbox" ? checked : value });
  };
  useEffect(() => {
    ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
      if (value !== repPass) {
        return false;
      }
      return true;
    });
    ValidatorForm.addValidationRule("isTruthy", (value) => value);
    sessionStorage.setItem('reloadMap',1)
  }, []);

  const handleRegister = (e) => {
    e.preventDefault();
    dispatch({ type: LOADING_MESSAGE, isLoading: true });
    dispatch(updatePassword(formData));
  };

  return (
    <div className="MainLoginBg">
      <div className="SigninWrapp">
        <div className="SigninTitle">
          <div className="inlineBox">
            <h2>Update Password</h2>
          </div>
          <div className="inlineBox text-right">
            <img src={SigninImage} alt="" />
          </div>
        </div>
        <TabPanel value={0} index={0}>
          <ValidatorForm className="FormStyle" onSubmit={handleRegister}>
            <div>
              <label>Old Password</label>
              <TextValidator
                placeholder="Old Password"
                fullWidth
                onChange={changeInputHandler}
                name="oldPassword"
                autoComplete="new-password"
                type="password"
                validators={["required"]}
                errorMessages={["This field is required."]}
                value={formData.oldPassword}
              />
            </div>
            <div>
              <label>Password</label>
              <TextValidator
                placeholder="Password"
                fullWidth
                onChange={changeInputHandler}
                name="password"
                autoComplete="new-password"
                type="password"
                validators={[
                  "required",
                  "matchRegexp:^(?=.*[0-9])(?=.*[a-z])[a-zA-Z0-9!@#$%^&*]{8,}$",
                ]}
                errorMessages={[
                  "This field is required.",
                  "Password should be minimum eight characters, at least one letter and one number",
                ]}
                value={formData.password}
              />
            </div>
            <div>
              <label>Confirm Password</label>
              <TextValidator
                placeholder="Confirm Password"
                fullWidth
                onChange={changeInputHandler}
                name="repeatPassword"
                autoComplete="new-password"
                type="password"
                validators={["isPasswordMatch", "required"]}
                errorMessages={["password mismatch", "This field is required."]}
                value={formData.repeatPassword}
              />
            </div>
            <div>
              <Button
                type="submit"
                variant="contained"
                className="btn-block btnTheme"
                disabled={isLoading}
              >Save
              </Button>
            </div>
          </ValidatorForm>
        </TabPanel>
      </div>
    </div>
  );
};

function TabPanel(props) {
  const { children, value, index } = props;
  return <div>{value === index && <h1>{children}</h1>}</div>;
}

export default UpdatePassword;
