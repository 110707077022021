import React, { useState } from "react";
import icon7 from "../images/building.png";
import icon3 from "../images/darkblueMarker.png";
import icon2 from "../images/greenMarker.png";
import icon6 from "../images/greenPin.png";
import icon9 from "../images/hospital.png";
import icon10 from "../images/house.png";
import icon4 from "../images/orangeMarker.png";
import icon1 from "../images/redMarker.png";
import icon5 from "../images/redPin.png";
import icon8 from "../images/tree.png";
import icon11 from "../images/new icons/1-lv.png";
import icon12 from "../images/new icons/1.png";
import icon13 from "../images/new icons/10-lv.png";
import icon14 from "../images/new icons/10.png";
import icon15 from "../images/new icons/190201-2016-animal-paw_4x.png";
import icon16 from "../images/new icons/2-lv.png";
import icon17 from "../images/new icons/3-lv.png";
import icon18 from "../images/new icons/2.png";
import icon19 from "../images/new icons/3.png";
import icon20 from "../images/new icons/32x32.png";
import icon21 from "../images/new icons/360cities.png";
import icon22 from "../images/new icons/4-lv.png";
import icon23 from "../images/new icons/4.png";
import icon24 from "../images/new icons/5-lv.png";
import icon25 from "../images/new icons/5.png";
import icon26 from "../images/new icons/6-lv.png";
import icon27 from "../images/new icons/6.png";
import icon28 from "../images/new icons/7-lv.png";
import icon29 from "../images/new icons/7.png";
import icon30 from "../images/new icons/8-lv.png";
import icon31 from "../images/new icons/8.png";
import icon32 from "../images/new icons/9-lv.png";
import icon33 from "../images/new icons/9.png";
import icon34 from "../images/new icons/A.png";
import icon35 from "../images/new icons/airports.png";
import icon36 from "../images/new icons/arrow-reverse.png";
import icon37 from "../images/new icons/arrow.png";
import icon38 from "../images/new icons/arrowff.gif";
import icon39 from "../images/new icons/arrowshadow.png";
import icon40 from "../images/new icons/arrowtransparent.png";
import icon41 from "../images/new icons/arts.png";
import icon42 from "../images/new icons/B.png";
import icon43 from "../images/new icons/bars.png";
import icon44 from "../images/new icons/blu-blank-lv.png";
import icon45 from "../images/new icons/blu-blank.png";
import icon46 from "../images/new icons/blu-circle-lv.png";
import icon47 from "../images/new icons/blu-circle.png";
import icon48 from "../images/new icons/blu-diamond-lv.png";
import icon49 from "../images/new icons/blu-diamond.png";
import icon50 from "../images/new icons/blu-square-lv.png";
import icon51 from "../images/new icons/blu-square.png";
import icon52 from "../images/new icons/blu-stars-lv.png";
import icon53 from "../images/new icons/blu-stars.png";
import icon54 from "../images/new icons/blue-pushpin.png";
import icon55 from "../images/new icons/broken_link.png";
import icon56 from "../images/new icons/bus.png";
import icon57 from "../images/new icons/C.png";
import icon58 from "../images/new icons/cabs.png";
import icon59 from "../images/new icons/camera.png";
import icon60 from "../images/new icons/campfire.png";
import icon61 from "../images/new icons/campground.png";
import icon62 from "../images/new icons/capital_big.png";
import icon63 from "../images/new icons/capital_big_highlight.png";
import icon64 from "../images/new icons/capital_small.png";
import icon65 from "../images/new icons/capital_small_highlight.png";
import icon66 from "../images/new icons/caution.png";
import icon67 from "../images/new icons/church.png";
import icon68 from "../images/new icons/close.gif";
import icon69 from "../images/new icons/coffee.png";
import icon70 from "../images/new icons/convenience.png";
import icon71 from "../images/new icons/cross-hairs.png";
import icon72 from "../images/new icons/cross-hairs_highlight.png";
import icon73 from "../images/new icons/cycling.png";
import icon74 from "../images/new icons/D.png";
import icon75 from "../images/new icons/dining.png";
import icon76 from "../images/new icons/dir_0.png";
import icon77 from "../images/new icons/dir_102.png";
import icon78 from "../images/new icons/dir_105.png";
import icon79 from "../images/new icons/dir_108.png";
import icon80 from "../images/new icons/dir_111.png";
import icon81 from "../images/new icons/dir_114.png";
import icon82 from "../images/new icons/dir_117.png";
import icon83 from "../images/new icons/dir_12.png";
import icon84 from "../images/new icons/dir_15.png";
import icon85 from "../images/new icons/dir_18.png";
import icon86 from "../images/new icons/dir_21.png";
import icon87 from "../images/new icons/dir_24.png";
import icon88 from "../images/new icons/dir_27.png";
import icon89 from "../images/new icons/dir_3.png";
import icon90 from "../images/new icons/dir_30.png";
import icon91 from "../images/new icons/dir_33.png";
import icon92 from "../images/new icons/dir_36.png";
import icon93 from "../images/new icons/dir_39.png";
import icon94 from "../images/new icons/dir_42.png";
import icon95 from "../images/new icons/dir_45.png";
import icon96 from "../images/new icons/dir_48.png";
import icon97 from "../images/new icons/dir_51.png";
import icon98 from "../images/new icons/dir_54.png";
import icon99 from "../images/new icons/dir_57.png";
import icon100 from "../images/new icons/dir_6.png";
import icon101 from "../images/new icons/dir_60.png";
import icon102 from "../images/new icons/dir_63.png";
import icon103 from "../images/new icons/dir_66.png";
import icon104 from "../images/new icons/dir_69.png";
import icon105 from "../images/new icons/dir_72.png";
import icon106 from "../images/new icons/dir_75.png";
import icon107 from "../images/new icons/dir_78.png";
import icon108 from "../images/new icons/dir_81.png";
import icon109 from "../images/new icons/dir_84.png";
import icon110 from "../images/new icons/dir_87.png";
import icon111 from "../images/new icons/dir_9.png";
import icon112 from "../images/new icons/dir_90.png";
import icon113 from "../images/new icons/dir_93.png";
import icon114 from "../images/new icons/dir_96.png";
import icon115 from "../images/new icons/dir_99.png";
import icon116 from "../images/new icons/dir_walk_0.png";
import icon117 from "../images/new icons/dir_walk_102.png";
import icon118 from "../images/new icons/dir_walk_105.png";
import icon119 from "../images/new icons/dir_walk_108.png";
import icon120 from "../images/new icons/dir_walk_111.png";
import icon121 from "../images/new icons/dir_walk_114.png";
import icon122 from "../images/new icons/dir_walk_117.png";
import icon123 from "../images/new icons/dir_walk_12.png";
import icon124 from "../images/new icons/dir_walk_15.png";
import icon125 from "../images/new icons/dir_walk_18.png";
import icon126 from "../images/new icons/dir_walk_21.png";
import icon127 from "../images/new icons/dir_walk_24.png";
import icon128 from "../images/new icons/dir_walk_27.png";
import icon129 from "../images/new icons/dir_walk_3.png";
import icon130 from "../images/new icons/dir_walk_30.png";
import icon131 from "../images/new icons/dir_walk_33.png";
import icon132 from "../images/new icons/dir_walk_36.png";
import icon133 from "../images/new icons/dir_walk_39.png";
import icon134 from "../images/new icons/dir_walk_42.png";
import icon135 from "../images/new icons/dir_walk_45.png";
import icon136 from "../images/new icons/dir_walk_48.png";
import icon137 from "../images/new icons/dir_walk_51.png";
import icon138 from "../images/new icons/dir_walk_54.png";
import icon139 from "../images/new icons/dir_walk_57.png";
import icon140 from "../images/new icons/dir_walk_6.png";
import icon141 from "../images/new icons/dir_walk_60.png";
import icon142 from "../images/new icons/dir_walk_63.png";
import icon143 from "../images/new icons/dir_walk_66.png";
import icon144 from "../images/new icons/dir_walk_69.png";
import icon145 from "../images/new icons/dir_walk_72.png";
import icon146 from "../images/new icons/dir_walk_75.png";
import icon147 from "../images/new icons/dir_walk_78.png";
import icon148 from "../images/new icons/dir_walk_81.png";
import icon149 from "../images/new icons/dir_walk_84.png";
import icon150 from "../images/new icons/dir_walk_87.png";
import icon151 from "../images/new icons/dir_walk_9.png";
import icon152 from "../images/new icons/dir_walk_90.png";
import icon153 from "../images/new icons/dir_walk_93.png";
import icon154 from "../images/new icons/dir_walk_96.png";
import icon155 from "../images/new icons/dir_walk_99.png";
import icon156 from "../images/new icons/dollar.png";
import icon157 from "../images/new icons/donut.png";
import icon158 from "../images/new icons/E.png";
import icon159 from "../images/new icons/earthquake.png";
import icon160 from "../images/new icons/electronics.png";
import icon161 from "../images/new icons/euro.png";
import icon162 from "../images/new icons/F.png";
import icon163 from "../images/new icons/falling_rocks.png";
import icon164 from "../images/new icons/ferry.png";
import icon165 from "../images/new icons/firedept.png";
import icon166 from "../images/new icons/fishing.png";
import icon167 from "../images/new icons/flag.png";
import icon168 from "../images/new icons/forbidden.png";
import icon169 from "../images/new icons/G.png";
import icon170 from "../images/new icons/gas_stations.png";
import icon171 from "../images/new icons/go-lv.png";
import icon172 from "../images/new icons/go.png";
import icon173 from "../images/new icons/golf.png";
import icon174 from "../images/new icons/grn-blank-lv.png";
import icon175 from "../images/new icons/grn-blank.png";
import icon176 from "../images/new icons/grn-circle-lv.png";
import icon177 from "../images/new icons/grn-circle.png";
import icon178 from "../images/new icons/grn-diamond-lv.png";
import icon179 from "../images/new icons/grn-diamond.png";
import icon180 from "../images/new icons/grn-pushpin.png";
import icon181 from "../images/new icons/grn-square-lv.png";
import icon182 from "../images/new icons/grn-square.png";
import icon183 from "../images/new icons/grn-stars-lv.png";
import icon184 from "../images/new icons/grn-stars.png";
import icon185 from "../images/new icons/grocery.png";
import icon186 from "../images/new icons/H.png";
import icon187 from "../images/new icons/heliport.png";
import icon188 from "../images/new icons/hide-arrow.png";
import icon189 from "../images/new icons/highway.png";
import icon190 from "../images/new icons/hiker.png";
import icon191 from "../images/new icons/homegardenbusiness.png";
import icon192 from "../images/new icons/horsebackriding.png";
import icon193 from "../images/new icons/hospitals.png";
import icon194 from "../images/new icons/I.png";
import icon195 from "../images/new icons/icon0.png";
import icon196 from "../images/new icons/icon0l.png";
import icon197 from "../images/new icons/icon1.png";
import icon198 from "../images/new icons/icon10.png";
import icon199 from "../images/new icons/icon11.png";
import icon200 from "../images/new icons/icon12.png";
import icon201 from "../images/new icons/icon13.png";
import icon202 from "../images/new icons/icon14.png";
import icon204 from "../images/new icons/icon16.png";
import icon205 from "../images/new icons/icon16l.png";
import icon206 from "../images/new icons/icon17.png";
import icon207 from "../images/new icons/icon17l.png";
import icon208 from "../images/new icons/icon18.png";
import icon209 from "../images/new icons/icon18l.png";
import icon210 from "../images/new icons/icon19.png";
import icon211 from "../images/new icons/icon19l.png";
import icon212 from "../images/new icons/icon1l.png";
import icon213 from "../images/new icons/icon2.png";
import icon214 from "../images/new icons/icon20.png";
import icon215 from "../images/new icons/icon20l.png";
import icon216 from "../images/new icons/icon21.png";
import icon217 from "../images/new icons/icon21l.png";
import icon218 from "../images/new icons/icon22.png";
import icon219 from "../images/new icons/icon22l.png";
import icon220 from "../images/new icons/icon23.png";
import icon221 from "../images/new icons/icon23l.png";
import icon222 from "../images/new icons/icon24.png";
import icon223 from "../images/new icons/icon24l.png";
import icon224 from "../images/new icons/icon25.png";
import icon225 from "../images/new icons/icon25l.png";
import icon226 from "../images/new icons/icon26.png";
import icon227 from "../images/new icons/icon26l.png";
import icon228 from "../images/new icons/icon27.png";
import icon229 from "../images/new icons/icon27l.png";
import icon230 from "../images/new icons/icon28.png";
import icon231 from "../images/new icons/icon28l.png";
import icon232 from "../images/new icons/icon29.png";
import icon233 from "../images/new icons/icon29l.png";
import icon234 from "../images/new icons/icon3.png";
import icon235 from "../images/new icons/icon30.png";
import icon236 from "../images/new icons/icon30l.png";
import icon237 from "../images/new icons/icon31.png";
import icon238 from "../images/new icons/icon31l.png";
import icon239 from "../images/new icons/icon32.png";
import icon240 from "../images/new icons/icon32l.png";
import icon241 from "../images/new icons/icon33.png";
import icon242 from "../images/new icons/icon33l.png";
import icon243 from "../images/new icons/icon34.png";
import icon244 from "../images/new icons/icon34l.png";
import icon245 from "../images/new icons/icon35.png";
import icon246 from "../images/new icons/icon35l.png";
import icon247 from "../images/new icons/icon36.png";
import icon248 from "../images/new icons/icon36l.png";
import icon249 from "../images/new icons/icon37.png";
import icon250 from "../images/new icons/icon37l.png";
import icon251 from "../images/new icons/icon38.png";
import icon252 from "../images/new icons/icon38l.png";
import icon253 from "../images/new icons/icon39.png";
import icon254 from "../images/new icons/icon39l.png";
import icon255 from "../images/new icons/icon4.png";
import icon256 from "../images/new icons/icon40.png";
import icon257 from "../images/new icons/icon40l.png";
import icon258 from "../images/new icons/icon41.png";
import icon259 from "../images/new icons/icon41l.png";
import icon260 from "../images/new icons/icon42.png";
import icon261 from "../images/new icons/icon42l.png";
import icon262 from "../images/new icons/icon43.png";
import icon263 from "../images/new icons/icon43l.png";
import icon264 from "../images/new icons/icon44.png";
import icon265 from "../images/new icons/icon44l.png";
import icon266 from "../images/new icons/icon45.png";
import icon267 from "../images/new icons/icon45l.png";
import icon268 from "../images/new icons/icon46.png";
import icon269 from "../images/new icons/icon46l.png";
import icon270 from "../images/new icons/icon47.png";
import icon271 from "../images/new icons/icon47l.png";
import icon272 from "../images/new icons/icon48.png";
import icon273 from "../images/new icons/icon48l.png";
import icon274 from "../images/new icons/icon49.png";
import icon275 from "../images/new icons/icon49l.png";
import icon276 from "../images/new icons/icon5.png";
import icon277 from "../images/new icons/icon50.png";
import icon278 from "../images/new icons/icon50l.png";
import icon279 from "../images/new icons/icon51.png";
import icon280 from "../images/new icons/icon51l.png";
import icon281 from "../images/new icons/icon52.png";
import icon282 from "../images/new icons/icon52l.png";
import icon283 from "../images/new icons/icon53.png";
import icon284 from "../images/new icons/icon53l.png";
import icon285 from "../images/new icons/icon54.png";
import icon286 from "../images/new icons/icon54l.png";
import icon288 from "../images/new icons/icon55l.png";
import icon289 from "../images/new icons/icon56.png";
import icon290 from "../images/new icons/icon56l.png";
import icon291 from "../images/new icons/icon57.png";
import icon292 from "../images/new icons/icon57l.png";
import icon293 from "../images/new icons/icon58.png";
import icon294 from "../images/new icons/icon58l.png";
import icon295 from "../images/new icons/icon59.png";
import icon296 from "../images/new icons/icon59l.png";
import icon297 from "../images/new icons/icon6.png";
import icon298 from "../images/new icons/icon60.png";
import icon299 from "../images/new icons/icon60l.png";
import icon300 from "../images/new icons/icon61.png";
import icon301 from "../images/new icons/icon61l.png";
import icon302 from "../images/new icons/icon62.png";
import icon303 from "../images/new icons/icon62l.png";
import icon304 from "../images/new icons/icon63.png";
import icon305 from "../images/new icons/icon63l.png";
import icon306 from "../images/new icons/icon7.png";
import icon307 from "../images/new icons/icon8.png";
import icon308 from "../images/new icons/icon8l.png";
import icon309 from "../images/new icons/icon9.png";
import icon310 from "../images/new icons/icon9l.png";
import icon311 from "../images/new icons/info-i.png";
import icon312 from "../images/new icons/info.png";
import icon313 from "../images/new icons/info_circle.png";
import icon314 from "../images/new icons/J.png";
import icon315 from "../images/new icons/K.png";
import icon316 from "../images/new icons/L.png";
import icon317 from "../images/new icons/lodging.png";
import icon318 from "../images/new icons/ltblu-blank.png";
import icon319 from "../images/new icons/ltblu-circle.png";
import icon320 from "../images/new icons/ltblu-diamond.png";
import icon321 from "../images/new icons/ltblu-pushpin.png";
import icon322 from "../images/new icons/ltblu-square.png";
import icon323 from "../images/new icons/ltblu-stars.png";
import icon324 from "../images/new icons/M.png";
import icon325 from "../images/new icons/man.png";
import icon326 from "../images/new icons/marina.png";
import icon327 from "../images/new icons/mechanic.png";
import icon328 from "../images/new icons/mm_20_orange.png";
import icon329 from "../images/new icons/mm_20_purple.png";
import icon330 from "../images/new icons/motorcycling.png";
import icon331 from "../images/new icons/mountains.png";
import icon332 from "../images/new icons/movies.png";
import icon333 from "../images/new icons/N.png";
import icon334 from "../images/new icons/O.png";
import icon335 from "../images/new icons/open-diamond.png";
import icon336 from "../images/new icons/orange-blank.png";
import icon337 from "../images/new icons/orange-circle.png";
import icon338 from "../images/new icons/orange-diamond.png";
import icon339 from "../images/new icons/orange-square.png";
import icon340 from "../images/new icons/orange-stars.png";
import icon341 from "../images/new icons/P.png";
import icon342 from "../images/new icons/palette-4.png";
import icon343 from "../images/new icons/parking_lot.png";
import icon344 from "../images/new icons/parks.png";
import icon345 from "../images/new icons/partly_cloudy.png";
import icon346 from "../images/new icons/pause-lv.png";
import icon347 from "../images/new icons/pause.png";
import icon348 from "../images/new icons/pharmacy_rx.png";
import icon349 from "../images/new icons/phone.png";
import icon350 from "../images/new icons/picnic.png";
import icon351 from "../images/new icons/pink-blank.png";
import icon352 from "../images/new icons/pink-circle.png";
import icon353 from "../images/new icons/pink-diamond.png";
import icon354 from "../images/new icons/pink-pushpin.png";
import icon355 from "../images/new icons/pink-square.png";
import icon356 from "../images/new icons/pink-stars.png";
import icon357 from "../images/new icons/placemark_circle.png";
import icon358 from "../images/new icons/placemark_circle_highlight.png";
import icon359 from "../images/new icons/placemark_square.png";
import icon360 from "../images/new icons/placemark_square_highlight.png";
import icon361 from "../images/new icons/play.png";
import icon362 from "../images/new icons/poi.png";
import icon363 from "../images/new icons/police.png";
import icon364 from "../images/new icons/polygon.png";
import icon365 from "../images/new icons/post_office.png";
import icon366 from "../images/new icons/purple-blank.png";
import icon367 from "../images/new icons/purple-circle-lv.png";
import icon368 from "../images/new icons/purple-circle.png";
import icon369 from "../images/new icons/purple-diamond-lv.png";
import icon370 from "../images/new icons/purple-diamond.png";
import icon371 from "../images/new icons/purple-pushpin.png";
import icon372 from "../images/new icons/purple-square-lv.png";
import icon373 from "../images/new icons/purple-square.png";
import icon374 from "../images/new icons/purple-stars-lv.png";
import icon375 from "../images/new icons/purple-stars.png";
import icon376 from "../images/new icons/Q.png";
import icon377 from "../images/new icons/R.png";
import icon378 from "../images/new icons/rail.png";
import icon379 from "../images/new icons/rainy.png";
import icon380 from "../images/new icons/ranger_station.png";
import icon381 from "../images/new icons/realestate.png";
import icon382 from "../images/new icons/red-circle-lv.png";
import icon383 from "../images/new icons/red-circle.png";
import icon384 from "../images/new icons/red-diamond-lv.png";
import icon385 from "../images/new icons/red-diamond.png";
import icon386 from "../images/new icons/red-pushpin.png";
import icon387 from "../images/new icons/red-square-lv.png";
import icon388 from "../images/new icons/red-square.png";
import icon389 from "../images/new icons/red-stars-lv.png";
import icon390 from "../images/new icons/red-stars.png";
import icon391 from "../images/new icons/road_shield1.png";
import icon392 from "../images/new icons/road_shield2.png";
import icon393 from "../images/new icons/road_shield3.png";
import icon394 from "../images/new icons/route.png";
import icon395 from "../images/new icons/ruler.png";
import icon396 from "../images/new icons/S.png";
import icon397 from "../images/new icons/sailing.png";
import icon398 from "../images/new icons/salon.png";
import icon399 from "../images/new icons/schools.png";
import icon400 from "../images/new icons/shaded_dot.png";
import icon401 from "../images/new icons/shopping.png";
import icon402 from "../images/new icons/show-arrow.png";
import icon403 from "../images/new icons/ski.png";
import icon404 from "../images/new icons/snack_bar.png";
import icon405 from "../images/new icons/snowflake_simple.png";
import icon406 from "../images/new icons/square.png";
import icon407 from "../images/new icons/star.png";
import icon408 from "../images/new icons/stop-lv.png";
import icon409 from "../images/new icons/stop.png";
import icon411 from "../images/new icons/subway.png";
import icon412 from "../images/new icons/sunny.png";
import icon413 from "../images/new icons/swc.png";
import icon414 from "../images/new icons/swimming.png";
import icon415 from "../images/new icons/T.png";
import icon416 from "../images/new icons/target.png";
import icon417 from "../images/new icons/terrain.png";
import icon418 from "../images/new icons/thunderstorm.png";
import icon419 from "../images/new icons/toilets.png";
import icon420 from "../images/new icons/track-0.png";
import icon421 from "../images/new icons/track-1.png";
import icon422 from "../images/new icons/track-10.png";
import icon423 from "../images/new icons/track-11.png";
import icon424 from "../images/new icons/track-12.png";
import icon425 from "../images/new icons/track-13.png";
import icon426 from "../images/new icons/track-14.png";
import icon427 from "../images/new icons/track-15.png";
import icon428 from "../images/new icons/track-2.png";
import icon429 from "../images/new icons/track-3.png";
import icon430 from "../images/new icons/track-4.png";
import icon431 from "../images/new icons/track-5.png";
import icon432 from "../images/new icons/track-6.png";
import icon433 from "../images/new icons/track-7.png";
import icon434 from "../images/new icons/track-8.png";
import icon435 from "../images/new icons/track-9.png";
import icon436 from "../images/new icons/track-none.png";
import icon437 from "../images/new icons/traffic.png";
import icon438 from "../images/new icons/trail.png";
import icon439 from "../images/new icons/tram.png";
import icon440 from "../images/new icons/triangle.png";
import icon441 from "../images/new icons/truck.png";
import icon442 from "../images/new icons/U.png";
import icon443 from "../images/new icons/V.png";
import icon444 from "../images/new icons/volcano.png";
import icon445 from "../images/new icons/W.png";
import icon446 from "../images/new icons/water.png";
import icon447 from "../images/new icons/webcam.png";
import icon448 from "../images/new icons/wheel_chair_accessible.png";
import icon449 from "../images/new icons/wht-blank-lv.png";
import icon450 from "../images/new icons/wht-blank.png";
import icon451 from "../images/new icons/wht-circle-lv.png";
import icon452 from "../images/new icons/wht-circle.png";
import icon453 from "../images/new icons/wht-diamond-lv.png";
import icon454 from "../images/new icons/wht-diamond.png";
import icon455 from "../images/new icons/wht-pushpin.png";
import icon456 from "../images/new icons/wht-square-lv.png";
import icon457 from "../images/new icons/wht-square.png";
import icon458 from "../images/new icons/wht-stars-lv.png";
import icon459 from "../images/new icons/wht-stars.png";
import icon460 from "../images/new icons/woman.png";
import icon461 from "../images/new icons/X.png";
import icon462 from "../images/new icons/Y.png";
import icon463 from "../images/new icons/yen.png";
import icon464 from "../images/new icons/ylw-blank-lv.png";
import icon465 from "../images/new icons/ylw-blank.png";
import icon466 from "../images/new icons/ylw-circle-lv.png";
import icon467 from "../images/new icons/ylw-circle.png";
import icon468 from "../images/new icons/ylw-diamond-lv.png";
import icon469 from "../images/new icons/ylw-diamond.png";
import icon470 from "../images/new icons/ylw-pushpin.png";
import icon471 from "../images/new icons/ylw-square-lv.png";
import icon472 from "../images/new icons/ylw-square.png";
import icon473 from "../images/new icons/ylw-stars-lv.png";
import icon474 from "../images/new icons/Z.png";
import icon475 from "../images/new icons/ylw-stars.png";
import { Radio } from "antd";
import Scroll from "./Scroll";
import Tooltip from "@material-ui/core/Tooltip";
import TextField from "@material-ui/core/TextField";
const IconStyles = () => {
  const [searchField, setSearchField] = useState("");
  const listObj = [
    { value: "redMarker", src: icon1 },

    { value: "greenMarker", src: icon2 },

    { value: "darkblueMarker", src: icon3 },

    { value: "orangeMarker", src: icon4 },

    { value: "redPin", src: icon5 },

    { value: "greenPin", src: icon6 },

    { value: "building", src: icon7 },

    { value: "tree", src: icon8 },

    { value: "hospital", src: icon9 },

    { value: "house", src: icon10 },

    { value: "1-lv", src: icon11 },

    { value: "1", src: icon12 },

    { value: "10-lv", src: icon13 },

    { value: "10", src: icon14 },

    { value: "190201-2016-animal-paw_4x", src: icon15 },

    { value: "2-lv", src: icon16 },

    { value: "3-lv", src: icon17 },

    { value: "2", src: icon18 },

    { value: "3", src: icon19 },

    { value: "32x32", src: icon20 },

    { value: "360cities", src: icon21 },

    { value: "4-lv", src: icon22 },

    { value: "4", src: icon23 },

    { value: "5-lv", src: icon24 },

    { value: "5", src: icon25 },

    { value: "6-lv", src: icon26 },

    { value: "6", src: icon27 },

    { value: "7-lv", src: icon28 },

    { value: "7", src: icon29 },

    { value: "8-lv", src: icon30 },

    { value: "8", src: icon31 },

    { value: "9-lv", src: icon32 },

    { value: "9", src: icon33 },

    { value: "A", src: icon34 },

    { value: "airports", src: icon35 },

    { value: "arrow-reverse", src: icon36 },

    { value: "arrow", src: icon37 },

    { value: "arrowff", src: icon38 },

    { value: "arrowshadow", src: icon39 },

    { value: "arrowtransparent", src: icon40 },

    { value: "arts", src: icon41 },

    { value: "B", src: icon42 },

    { value: "bars", src: icon43 },

    { value: "blu-blank-lv", src: icon44 },

    { value: "blu-blank", src: icon45 },

    { value: "blu-circle-lv", src: icon46 },

    { value: "blu-circle", src: icon47 },

    { value: "blu-diamond-lv", src: icon48 },

    { value: "blu-diamond", src: icon49 },

    { value: "blu-square-lv", src: icon50 },

    { value: "blu-square", src: icon51 },

    { value: "blu-stars-lv", src: icon52 },

    { value: "blu-stars", src: icon53 },

    { value: "blue-pushpin", src: icon54 },

    { value: "broken_link", src: icon55 },

    { value: "bus", src: icon56 },

    { value: "C", src: icon57 },

    { value: "cabs", src: icon58 },

    { value: "camera", src: icon59 },

    { value: "campfire", src: icon60 },

    { value: "campground", src: icon61 },

    { value: "capital_big", src: icon62 },

    { value: "capital_big_highlight", src: icon63 },

    { value: "capital_small", src: icon64 },

    { value: "capital_small_highlight", src: icon65 },

    { value: "caution", src: icon66 },

    { value: "church", src: icon67 },

    { value: "close", src: icon68 },

    { value: "coffee", src: icon69 },

    { value: "convenience", src: icon70 },

    { value: "cross-hairs", src: icon71 },

    { value: "cross-hairs_highlight", src: icon72 },

    { value: "cycling", src: icon73 },

    { value: "D", src: icon74 },

    { value: "dining", src: icon75 },

    { value: "dir_0", src: icon76 },

    { value: "dir_102", src: icon77 },

    { value: "dir_105", src: icon78 },

    { value: "dir_108", src: icon79 },

    { value: "dir_111", src: icon80 },

    { value: "dir_114", src: icon81 },

    { value: "dir_117", src: icon82 },

    { value: "dir_12", src: icon83 },

    { value: "dir_15", src: icon84 },

    { value: "dir_18", src: icon85 },

    { value: "dir_21", src: icon86 },

    { value: "dir_24", src: icon87 },

    { value: "dir_27", src: icon88 },

    { value: "dir_3", src: icon89 },

    { value: "dir_30", src: icon90 },

    { value: "dir_33", src: icon91 },

    { value: "dir_36", src: icon92 },

    { value: "dir_39", src: icon93 },

    { value: "dir_42", src: icon94 },

    { value: "dir_45", src: icon95 },

    { value: "dir_48", src: icon96 },

    { value: "dir_51", src: icon97 },

    { value: "dir_54", src: icon98 },

    { value: "dir_57", src: icon99 },

    { value: "dir_6", src: icon100 },

    { value: "dir_60", src: icon101 },

    { value: "dir_63", src: icon102 },

    { value: "dir_66", src: icon103 },

    { value: "dir_69", src: icon105 },

    { value: "dir_72", src: icon106 },

    { value: "dir_75", src: icon107 },

    { value: "dir_78", src: icon108 },

    { value: "dir_81", src: icon109 },

    { value: "dir_84", src: icon110 },

    { value: "dir_87", src: icon111 },

    { value: "dir_9", src: icon112 },

    { value: "dir_90", src: icon113 },

    { value: "dir_93", src: icon114 },

    { value: "dir_96", src: icon115 },

    { value: "dir_99", src: icon116 },

    { value: "dir_walk_0", src: icon117 },

    { value: "dir_walk_102", src: icon118 },

    { value: "dir_walk_105", src: icon119 },

    { value: "dir_walk_108", src: icon120 },

    { value: "dir_walk_111", src: icon121 },

    { value: "dir_walk_114", src: icon122 },

    { value: "dir_walk_117", src: icon123 },

    { value: "dir_walk_12", src: icon124 },

    { value: "dir_walk_15", src: icon125 },

    { value: "dir_walk_18", src: icon126 },

    { value: "dir_walk_21", src: icon127 },

    { value: "dir_walk_24", src: icon128 },

    { value: "dir_walk_27", src: icon129 },

    { value: "dir_walk_3", src: icon130 },

    { value: "dir_walk_30", src: icon131 },

    { value: "dir_walk_33", src: icon132 },

    { value: "dir_walk_36", src: icon133 },

    { value: "dir_walk_39", src: icon134 },

    { value: "dir_walk_42", src: icon135 },

    { value: "dir_walk_45", src: icon136 },

    { value: "dir_walk_48", src: icon137 },

    { value: "dir_walk_51", src: icon138 },

    { value: "dir_walk_54", src: icon139 },

    { value: "dir_walk_57", src: icon140 },

    { value: "dir_walk_6", src: icon141 },

    { value: "dir_walk_60", src: icon142 },

    { value: "dir_walk_63", src: icon143 },

    { value: "dir_walk_66", src: icon144 },

    { value: "dir_walk_69", src: icon145 },

    { value: "dir_walk_72", src: icon146 },

    { value: "dir_walk_75", src: icon147 },

    { value: "dir_walk_78", src: icon148 },

    { value: "dir_walk_81", src: icon149 },

    { value: "dir_walk_84", src: icon150 },

    { value: "dir_walk_87", src: icon151 },

    { value: "dir_walk_9", src: icon152 },

    { value: "dir_walk_90", src: icon153 },

    { value: "dir_walk_93", src: icon154 },

    { value: "dir_walk_96", src: icon155 },

    { value: "dir_walk_99", src: icon156 },

    { value: "dollar", src: icon157 },

    { value: "donut", src: icon158 },

    { value: "E", src: icon159 },

    { value: "earthquake", src: icon160 },

    { value: "electronics", src: icon161 },

    { value: "euro", src: icon162 },

    { value: "F", src: icon163 },

    { value: "falling_rocks", src: icon164 },

    { value: "ferry", src: icon165 },

    { value: "firedept", src: icon166 },

    { value: "fishing", src: icon167 },

    { value: "flag", src: icon168 },

    { value: "forbidden", src: icon169 },

    { value: "G", src: icon170 },

    { value: "gas_stations", src: icon171 },

    { value: "go-lv", src: icon172 },

    { value: "go", src: icon173 },

    { value: "golf", src: icon174 },

    { value: "grn-blank-lv", src: icon175 },

    { value: "grn-blank", src: icon176 },

    { value: "grn-circle-lv", src: icon177 },

    { value: "grn-circle", src: icon178 },

    { value: "grn-diamond-lv", src: icon179 },

    { value: "grn-diamond", src: icon180 },

    { value: "grn-pushpin", src: icon181 },

    { value: "grn-square-lv", src: icon182 },

    { value: "grn-square", src: icon183 },

    { value: "grn-stars-lv", src: icon184 },

    { value: "grn-stars", src: icon185 },

    { value: "grocery", src: icon186 },

    { value: "H", src: icon187 },

    { value: "heliport", src: icon188 },

    { value: "hide-arrow", src: icon189 },

    { value: "highway", src: icon190 },

    { value: "hiker", src: icon191 },

    { value: "homegardenbusiness", src: icon191 },

    { value: "horsebackriding", src: icon192 },

    { value: "hospitals", src: icon193 },

    { value: "I", src: icon194 },

    { value: "icon0", src: icon195 },

    { value: "icon0l", src: icon196 },

    { value: "icon1", src: icon197 },

    { value: "icon10", src: icon198 },

    { value: "icon11", src: icon199 },

    { value: "icon12", src: icon200 },

    { value: "icon13", src: icon201 },

    { value: "icon14", src: icon202 },

    { value: "icon16", src: icon204 },

    { value: "icon16l", src: icon205 },

    { value: "icon17", src: icon206 },

    { value: "icon17l", src: icon207 },

    { value: "icon18", src: icon208 },

    { value: "icon18l", src: icon209 },

    { value: "icon19", src: icon210 },

    { value: "icon19l", src: icon211 },

    { value: "icon1l", src: icon212 },

    { value: "icon2", src: icon213 },

    { value: "icon20", src: icon214 },

    { value: "icon20l", src: icon215 },

    { value: "icon21", src: icon216 },

    { value: "icon21l", src: icon217 },

    { value: "icon22", src: icon218 },

    { value: "icon22l", src: icon219 },

    { value: "icon23", src: icon220 },

    { value: "icon23l", src: icon221 },

    { value: "icon24", src: icon222 },

    { value: "icon24l", src: icon223 },

    { value: "icon25", src: icon224 },

    { value: "icon25l", src: icon225 },

    { value: "icon26", src: icon226 },

    { value: "icon26l", src: icon227 },

    { value: "icon27", src: icon228 },

    { value: "icon27l", src: icon229 },

    { value: "icon28", src: icon230 },

    { value: "icon28l", src: icon231 },

    { value: "icon29", src: icon232 },

    { value: "icon29l", src: icon233 },

    { value: "icon3", src: icon234 },

    { value: "icon30", src: icon235 },

    { value: "icon30l", src: icon236 },

    { value: "icon31", src: icon237 },

    { value: "icon31l", src: icon238 },

    { value: "icon32", src: icon239 },

    { value: "icon32l", src: icon240 },

    { value: "icon33", src: icon241 },

    { value: "icon33l", src: icon242 },

    { value: "icon34", src: icon243 },

    { value: "icon34l", src: icon244 },

    { value: "icon35", src: icon245 },

    { value: "icon35l", src: icon246 },

    { value: "icon36", src: icon247 },

    { value: "icon36l", src: icon248 },

    { value: "icon37", src: icon249 },

    { value: "icon37l", src: icon250 },

    { value: "icon38", src: icon251 },

    { value: "icon38l", src: icon252 },

    { value: "icon39", src: icon253 },

    { value: "icon39l", src: icon254 },

    { value: "icon4", src: icon255 },

    { value: "icon40", src: icon256 },

    { value: "icon40l", src: icon257 },

    { value: "icon41", src: icon258 },

    { value: "icon41l", src: icon259 },

    { value: "icon42", src: icon260 },

    { value: "icon42l", src: icon261 },

    { value: "icon43", src: icon262 },

    { value: "icon43l", src: icon263 },

    { value: "icon44", src: icon264 },

    { value: "icon44l", src: icon265 },

    { value: "icon45", src: icon266 },

    { value: "icon45l", src: icon267 },

    { value: "icon46", src: icon268 },

    { value: "icon46l", src: icon269 },

    { value: "icon47", src: icon270 },

    { value: "icon47l", src: icon271 },

    { value: "icon48", src: icon272 },

    { value: "icon48l", src: icon273 },

    { value: "icon49", src: icon274 },

    { value: "icon49l", src: icon275 },

    { value: "icon5", src: icon276 },

    { value: "icon50", src: icon277 },

    { value: "icon50l", src: icon278 },

    { value: "icon51", src: icon279 },

    { value: "icon51l", src: icon280 },

    { value: "icon52", src: icon281 },

    { value: "icon52l", src: icon282 },

    { value: "icon53", src: icon283 },

    { value: "icon53l", src: icon284 },

    { value: "icon54", src: icon285 },

    { value: "icon54l", src: icon286 },

    { value: "icon55l", src: icon288 },

    { value: "icon56", src: icon289 },

    { value: "icon56l", src: icon290 },

    { value: "icon57", src: icon291 },

    { value: "icon57l", src: icon292 },

    { value: "icon58", src: icon293 },

    { value: "icon58l", src: icon294 },

    { value: "icon59", src: icon295 },

    { value: "icon59l", src: icon296 },

    { value: "icon6", src: icon297 },

    { value: "icon60", src: icon298 },

    { value: "icon60l", src: icon299 },

    { value: "icon61", src: icon300 },

    { value: "icon61l", src: icon301 },

    { value: "icon62", src: icon302 },

    { value: "icon62l", src: icon303 },

    { value: "icon63", src: icon304 },

    { value: "icon63l", src: icon305 },

    { value: "icon7", src: icon306 },

    { value: "icon8", src: icon307 },

    { value: "icon8l", src: icon308 },

    { value: "icon9", src: icon309 },

    { value: "icon9l", src: icon310 },

    { value: "info-i", src: icon311 },

    { value: "info", src: icon312 },

    { value: "info_circle", src: icon313 },

    { value: "J", src: icon314 },

    { value: "K", src: icon315 },

    { value: "L", src: icon316 },

    { value: "lodging", src: icon317 },

    { value: "ltblu-blank", src: icon318 },

    { value: "ltblu-circle", src: icon319 },

    { value: "ltblu-diamond", src: icon320 },

    { value: "ltblu-pushpin", src: icon321 },

    { value: "ltblu-square", src: icon322 },

    { value: "ltblu-stars", src: icon323 },

    { value: "M", src: icon324 },

    { value: "man", src: icon325 },

    { value: "marina", src: icon326 },

    { value: "mechanic", src: icon327 },

    { value: "mm_20_orange", src: icon328 },

    { value: "mm_20_purple", src: icon329 },

    { value: "motorcycling", src: icon330 },

    { value: "mountains", src: icon331 },

    { value: "movies", src: icon332 },

    { value: "N", src: icon333 },

    { value: "O", src: icon334 },

    { value: "open-diamond", src: icon335 },

    { value: "orange-blank", src: icon336 },

    { value: "orange-circle", src: icon337 },

    { value: "orange-diamond", src: icon338 },

    { value: "orange-square", src: icon339 },

    { value: "orange-stars", src: icon340 },

    { value: "P", src: icon341 },

    { value: "palette-4", src: icon342 },

    { value: "parking_lot", src: icon343 },

    { value: "parks", src: icon344 },

    { value: "partly_cloudy", src: icon345 },

    { value: "pause-lv", src: icon346 },

    { value: "pause", src: icon347 },

    { value: "pharmacy_rx", src: icon348 },

    { value: "phone", src: icon349 },

    { value: "picnic", src: icon350 },

    { value: "pink-blank", src: icon351 },

    { value: "pink-circle", src: icon352 },

    { value: "pink-diamond", src: icon353 },

    { value: "pink-pushpin", src: icon354 },

    { value: "pink-square", src: icon355 },

    { value: "pink-stars", src: icon356 },

    { value: "placemark_circle", src: icon357 },

    { value: "placemark_circle_highlight", src: icon358 },

    { value: "placemark_square", src: icon359 },

    { value: "placemark_square_highlight", src: icon360 },

    { value: "play", src: icon361 },

    { value: "poi", src: icon362 },

    { value: "police", src: icon363 },

    { value: "polygon", src: icon364 },

    { value: "post_office", src: icon365 },

    { value: "purple-blank", src: icon366 },

    { value: "purple-circle-lv", src: icon367 },

    { value: "purple-circle", src: icon368 },

    { value: "purple-diamond-lv", src: icon369 },

    { value: "purple-diamond", src: icon370 },

    { value: "purple-pushpin", src: icon371 },

    { value: "purple-square-lv", src: icon372 },

    { value: "purple-square", src: icon373 },

    { value: "purple-stars-lv", src: icon374 },

    { value: "purple-stars", src: icon375 },

    { value: "Q", src: icon376 },

    { value: "R", src: icon377 },

    { value: "rail", src: icon378 },

    { value: "rainy", src: icon379 },

    { value: "ranger_station", src: icon380 },

    { value: "realestate", src: icon381 },

    { value: "red-circle-lv", src: icon382 },

    { value: "red-circle", src: icon383 },

    { value: "red-diamond-lv", src: icon384 },

    { value: "red-diamond", src: icon385 },

    { value: "red-pushpin", src: icon386 },

    { value: "red-square-lv", src: icon387 },

    { value: "red-square", src: icon388 },

    { value: "red-stars-lv", src: icon389 },

    { value: "red-stars", src: icon390 },

    { value: "road_shield1", src: icon391 },

    { value: "road_shield2", src: icon392 },

    { value: "road_shield3", src: icon393 },

    { value: "route", src: icon394 },

    { value: "ruler", src: icon395 },

    { value: "S", src: icon396 },

    { value: "sailing", src: icon397 },

    { value: "salon", src: icon398 },

    { value: "schools", src: icon399 },

    { value: "shaded_dot", src: icon400 },

    { value: "shopping", src: icon401 },

    { value: "show-arrow", src: icon402 },

    { value: "ski", src: icon403 },

    { value: "snack_bar", src: icon404 },

    { value: "snowflake_simple", src: icon405 },

    { value: "square", src: icon406 },

    { value: "star", src: icon407 },

    { value: "stop-lv", src: icon408 },

    { value: "stop", src: icon409 },

    { value: "subway", src: icon411 },

    { value: "sunny", src: icon412 },

    { value: "swc", src: icon413 },

    { value: "swimming", src: icon414 },

    { value: "T", src: icon415 },

    { value: "target", src: icon416 },

    { value: "terrain", src: icon417 },

    { value: "thunderstorm", src: icon418 },

    { value: "toilets", src: icon419 },

    { value: "track-0", src: icon420 },

    { value: "track-1", src: icon421 },

    { value: "track-10", src: icon422 },

    { value: "track-11", src: icon423 },

    { value: "track-12", src: icon424 },

    { value: "track-13", src: icon425 },

    { value: "track-14", src: icon426 },

    { value: "track-15", src: icon427 },

    { value: "track-2", src: icon428 },

    { value: "track-3", src: icon429 },

    { value: "track-4", src: icon430 },

    { value: "track-5", src: icon431 },

    { value: "track-6", src: icon432 },

    { value: "track-7", src: icon433 },

    { value: "track-8", src: icon434 },

    { value: "track-9", src: icon435 },

    { value: "track-none", src: icon436 },

    { value: "traffic", src: icon437 },

    { value: "trail", src: icon438 },

    { value: "tram", src: icon439 },

    { value: "triangle", src: icon440 },

    { value: "truck", src: icon441 },

    { value: "U", src: icon442 },

    { value: "V", src: icon443 },

    { value: "volcano", src: icon444 },

    { value: "W", src: icon445 },

    { value: "water", src: icon446 },

    { value: "webcam", src: icon447 },

    { value: "wheel_chair_accessible", src: icon448 },

    { value: "wht-blank-lv", src: icon449 },

    { value: "wht-blank", src: icon450 },

    { value: "wht-circle-lv", src: icon451 },

    { value: "wht-circle", src: icon452 },

    { value: "wht-diamond-lv", src: icon453 },

    { value: "wht-diamond", src: icon454 },

    { value: "wht-pushpin", src: icon455 },

    { value: "wht-square-lv", src: icon456 },

    { value: "wht-square", src: icon457 },

    { value: "wht-stars-lv", src: icon458 },

    { value: "wht-stars", src: icon459 },

    { value: "woman", src: icon460 },

    { value: "X", src: icon461 },

    { value: "Y", src: icon462 },

    { value: "yen", src: icon463 },

    { value: "ylw-blank-lv", src: icon464 },

    { value: "ylw-blank", src: icon465 },

    { value: "ylw-circle-lv", src: icon466 },

    { value: "ylw-circle", src: icon467 },

    { value: "ylw-diamond-lv", src: icon468 },

    { value: "ylw-diamond", src: icon469 },

    { value: "ylw-pushpin", src: icon470 },

    { value: "ylw-square-lv", src: icon471 },

    { value: "ylw-square", src: icon472 },

    { value: "ylw-stars-lv", src: icon473 },

    { value: "Z", src: icon474 },

    { value: "ylw-stars", src: icon475 },
  ];
  const handleChange = (e) => {
    setSearchField(e.target.value);
  };
  const filteredList = listObj.filter((obj) => {
    return obj.value.toLowerCase().includes(searchField.toLowerCase());
  });
  const searchList = () => {
    return (
      <Scroll>
        {filteredList.map((k, i) => {
          return (
            <Radio.Button value={k.value} key={k.value}>
              <Tooltip title={k.value} placement="top">
                <img src={k.src} />
              </Tooltip>
            </Radio.Button>
          );
        })}
      </Scroll>
    );
  };

  return (
    <div
      // style={{ margin: "0 15px" }}
      style={{ width: "300px" }}
    >
      <div style={{ margin: "0 0 12px 0" }}>
        <TextField
          fullWidth
          variant="outlined"
          label="Search Icons"
          placeholder="Search Icons"
          onChange={handleChange}
        />
      </div>
      {searchList()}
    </div>
  );
};
export default IconStyles;
