import { Input } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import { Link } from "react-router-dom";
import BlockIcon from "@material-ui/icons/Block";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import SendIcon from "@material-ui/icons/Send";
import ViewColumn from "@material-ui/icons/ViewColumn";
import MaterialTable from "material-table";
import React, { forwardRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ORGANIZATIONS, USERTYPE } from "../../actions/types";
import {
  createUser,
  deleteUser,
  getAllOrgs,
  getAllUsers,
  getUserTypes,
  resendVerification,
  updateUser,
} from "../../services/user.service";
import { displayMessage } from "./../common/common";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <BlockIcon {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}
function validateField(val) {
  return val != "";
}

const UserList = () => {
  const dispatch = useDispatch();
  const { userTypes, organizations } = useSelector((state) => state.auth);
  const [data, setData] = useState([]);
  const [backDropOpen, setBackDropOpen] = useState(true);
  const columns = [
    { title: "User ID", field: "id", editable: "never" },
    {
      title: "First Name",
      field: "firstName",

      validate: (rowData) => {
        if (validateField(rowData.firstName)) {
        }

        return validateField(rowData.firstName) && rowData.firstName
          ? ""
          : "First name is required";
      },
    },
    {
      title: "Last Name",
      field: "lastName",

      validate: (rowData) => {
        if (validateField(rowData.lastName)) {
        }

        return validateField(rowData.lastName) && rowData.lastName
          ? ""
          : "Last name is required";
      },
    },
    {
      title: "User Type",
      field: "typeID",
      lookup: userTypes,
      editable: "onUpdate",
    },
    {
      title: "Storage",
      field: "storageCapacity",
      editComponent: (props) => (
        <Input
          type="number"
          disabled={
            props.rowData.organizationID &&
            props.rowData.organizationID !== "null"
            // &&!props.rowData.isOrganizationAdmin
          }
          inputProps={{ min: 0 }}
          defaultValue={
            props.rowData.organizationID &&
            props.rowData.organizationID !== "null"
              ? // &&!props.rowData.isOrganizationAdmin
                null
              : props.value / 1024
          }
          onChange={(e) => props.onChange(e.target.value)}
        />
      ),
      type: "numeric",
      render: (col) => {
        return col.organizationID && col.organizationID !== "null"
          ? // && !col.isOrganizationAdmin
            "Handled by Organization"
          : (col.storageCapacity / 1024).toFixed(2) + " GB";
      },
      editable: "onUpdate",
    },
    {
      title: "Organization",
      field: "organizationID",
      lookup: organizations,
      editable: "onUpdate",
    },
    {
      title: "Role",
      field: "isOrganizationAdmin",
      lookup: {
        true: "Admin",
        false: "Member",
      },
      editable: "onUpdate",
    },
    {
      title: "Email",
      render: (col) => (
        <Link to={`/user-profile?user=${col.email}`}>{col.email}</Link>
      ),
      field: "email",
      // editable: "onAdd",
      validate: (rowData) => {
        if (validateEmail(rowData.email)) {
        }
        return validateEmail(rowData.email) ? "" : "Email is invalid";
      },
    },

    {
      title: "Company",
      field: "company",
      validate: (rowData) => {
        if (validateField(rowData.company)) {
        }

        return validateField(rowData.company) && rowData.company
          ? ""
          : "Company is required";
      },
    },

    // {
    //   title: "Location",
    //   field: "location",
    //   validate: (rowData) => {
    //     if (validateField(rowData.location)) {
    //     }

    //     return validateField(rowData.location) && rowData.location
    //       ? ""
    //       : "Location is required";
    //   },
    // },
    {
      title: "Status",
      field: "isVerified",
      lookup: {
        0: "Unverified",
        1: "Verified",
      },
      editable: "onUpdate",
    },
    {
      title: "Password",
      field: "password",

      editable: "onUpdate",
    },
  ];

  useEffect(() => {
    !userTypes &&
      getUserTypes().then((res) => {
        let types = {};

        res.data.userTypes.rows.forEach((singletype) => {
          types[singletype.type_id] = singletype.type_name;
        });
        dispatch({
          type: USERTYPE,
          payload: types,
        });
      });
    !organizations &&
      getAllOrgs().then((res) => {
        let types = {};
        res.data.organizations.forEach((singletype) => {
          types[singletype.organizationID] = singletype.organizationName;
        });
        types[null] = "No Organization";
        dispatch({
          type: ORGANIZATIONS,
          payload: types,
        });
      });
    userTypes &&
      organizations &&
      getAllUsers().then((res) => {
        setData(res.data.users.rows);
        setBackDropOpen(false);
      });
  }, []);

  return (
    <div className="margindiv usersTable">
      <MaterialTable
        localization={{
          body: {
            deleteTooltip: "Deactivate",
          },
        }}
        actions={[
          {
            icon: SendIcon,
            // disabled:rowData.isVerified?true:false,
            tooltip: "Resend Invite",
            onClick: (event, rowData) => {
              if (!rowData.isVerified) {
                new Promise((resolve, reject) => {
                  setBackDropOpen(true);
                  resendVerification(rowData.email)
                    .then((res) => {
                      resolve();
                      dispatch(
                        displayMessage("success", "Invite resent successfully")
                      );

                      setBackDropOpen(false);
                    })
                    .catch((error) => {
                      const message =
                        error?.response?.data?.message ||
                        error?.message ||
                        "User not created";
                      dispatch(displayMessage("error", message));
                      reject();
                    });
                });
              } else {
                dispatch(displayMessage("error", "User is already verified!"));
              }
            },
          },
        ]}
        options={{
          exportButton: true,
          exportAllData: true,
        }}
        icons={tableIcons}
        title="All Users"
        columns={columns}
        data={data}
        editable={{
          onRowAdd: (newData) => {
            return new Promise((resolve, reject) => {
              if (
                newData.firstName != "" &&
                newData.lastName != "" &&
                // newData.age != "" &&
                newData.company != "" &&
                // newData.location != "" &&
                validateEmail(newData.email)
              ) {
                createUser(newData)
                  .then((res, error) => {
                    if (error) {
                      dispatch(
                        displayMessage(
                          "error",
                          error.response
                            ? error.response.data
                            : "Error in user creation"
                        )
                      );
                      reject();
                      return;
                    }
                    newData["id"] = res.data.user.id;
                    newData["uuid"] = res.data.user.uuid;
                    newData["storageCapacity"] = res.data.user.storageCapacity;
                    newData["organizationID"] = res.data.user.organizationID;
                    newData["isOrganizationAdmin"] =
                      res.data.user.isOrganizationAdmin;
                    newData["isVerified"] = 0;
                    newData["typeID"] = 2;
                    setData([...data, newData]);

                    resolve();
                    dispatch(
                      displayMessage("success", "User added successfully")
                    );
                  })
                  .catch((error) => {
                    const message =
                      error?.response?.data?.message ||
                      error?.message ||
                      "User not created";
                    dispatch(displayMessage("error", message));
                    reject();
                  });
              } else {
                reject();
              }
            });
          },
          onRowUpdate: (newData, oldData) => {
            return new Promise((resolve, reject) => {
              if (
                newData.firstName != "" &&
                newData.lastName != "" &&
                // newData.age != "" &&
                newData.company != "" &&
                // newData.location != "" &&
                validateEmail(newData.email)
              ) {
                try {
                  updateUser(newData.uuid, {
                    email: newData.email,
                    firstName: newData.firstName,
                    lastName: newData.lastName,
                    company: newData.company,
                    typeID: Number(newData.typeID),
                    organizationID: newData.organizationID
                      ? Number(newData.organizationID)
                      : null,
                    isOrganizationAdmin: newData.organizationID
                      ? newData.isOrganizationAdmin !== "false" &&
                        newData.isOrganizationAdmin
                        ? true
                        : false
                      : false,
                    storageCapacity:
                      oldData.storageCapacity == newData.storageCapacity
                        ? oldData.storageCapacity
                        : newData.storageCapacity * 1024,
                    isVerified: newData.isVerified,
                    password: newData.password,
                  })
                    .then((res) => {
                      const dataUpdate = [...data];
                      const index = oldData.tableData.id;
                      delete newData["password"];
                      newData.storageCapacity =
                        oldData.storageCapacity == newData.storageCapacity
                          ? oldData.storageCapacity
                          : newData.storageCapacity * 1024;
                      if (oldData.typeID !== newData.typeID) {
                        newData.storageCapacity = res.data.user.storageCapacity;
                      }
                      dataUpdate[index] = newData;
                      setData([...dataUpdate]);

                      resolve();
                      dispatch(
                        displayMessage("success", "User updated successfully")
                      );
                    })
                    .catch((error) => {
                      const message =
                        error?.response?.data?.message ||
                        error?.message ||
                        "User not created";
                      dispatch(displayMessage("error", message));
                      reject();
                    });
                } catch (error) {
                  dispatch(displayMessage("error", "Error in updating user"));
                }
              } else {
                reject();
              }
            });
          },
          onRowDelete: (oldData) =>
            new Promise((resolve, reject) => {
              deleteUser(oldData.uuid)
                .then((res) => {
                  const dataDelete = [...data];
                  const index = oldData.tableData.id;
                  dataDelete.splice(index, 1);
                  setData([...dataDelete]);
                  resolve();
                  dispatch(
                    displayMessage("success", "User deactivated successfully")
                  );
                })
                .catch((error) => {
                  const message =
                    error?.response?.data?.message ||
                    error?.message ||
                    "User not created";
                  dispatch(displayMessage("error", message));
                  reject();
                });
            }),
        }}
      />
      <Backdrop
        open={backDropOpen}
        style={{ zIndex: 99999999999, color: "#fff" }}
      >
        <CircularProgress />
      </Backdrop>
    </div>
  );
};

export default UserList;
