import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LOADING_MESSAGE, SET_MESSAGE } from "../../actions/types";
import { reportIssue } from "../../services/user.service";

const UserSupport = () => {
  const dispatch = useDispatch();
  const { message, isLoading } = useSelector((state) => state.message);
  const [formData, setFormData] = useState({ title: "", description: "" });

  const changeInputHandler = (e) => {
    const { name, value, checked, type } = e.target;
    setFormData({ ...formData, [name]: type === "checkbox" ? checked : value });
  };

  const handleRequest = (e) => {
    e.preventDefault();
    if (formData.title.trim() === "" || formData.description.trim() === "") {
      dispatch({
        type: SET_MESSAGE,
        payload: {
          message: "Please fill all required Field",
          severity: "error",
          isOpen: true,
        },
      });
    } else {
      dispatch({ type: LOADING_MESSAGE, isLoading: true });
      reportIssue(formData)
        .then((response) => {
          if (response.data.status === 200) {
            const message =
              response?.data?.message ||
              response?.message ||
              "Issue reported successfully";
            dispatch({
              type: SET_MESSAGE,
              payload: { message: message, severity: "success", isOpen: true },
            });
          } else {
            dispatch({
              type: SET_MESSAGE,
              payload: { message: message, severity: "error", isOpen: true },
            });
          }
          setFormData({ title: "", description: "" });
        })
        .catch((error) => {
          const message =
            error?.response?.data?.message ||
            error?.message ||
            "Issue report error";
          dispatch({
            type: SET_MESSAGE,
            payload: { message: message, severity: "error", isOpen: true },
          });
        });
    }
  };

  return (
    <span className="layerBox">
      <Box className="TitleSection">
        <h3>Support</h3>
      </Box>
      <Box className="TitleSection">
        <h5>
          Visit the support page for knowledge base or support forum: <a href="https://www.metageo.io/support" target={"_blank"}>https://www.metageo.io/support</a><br/><br/>
          Submit a support ticket below.
        </h5>
      </Box>
      <div style={{ padding: "15px" }}>
        <TextField
          required
          inputProps={{ maxLength: 100 }}
          fullWidth
          name="title"
          onChange={changeInputHandler}
          id="outlined-required"
          label="Title"
          variant="outlined"
          value={formData.title}
        />
      </div>
      <div style={{ padding: "15px" }}>
        <TextField
          required
          fullWidth
          name="description"
          inputProps={{ maxLength: 2000 }}
          onChange={changeInputHandler}
          id="outlined-multiline-static"
          label="Description"
          multiline
          rows={10}
          value={formData.description}
          variant="outlined"
        />
      </div>
      <div style={{ padding: "15px", float: "right" }}>
        <Button
          onClick={handleRequest}
          variant="contained"
          color="primary"
          disabled={isLoading}
        >
          Submit
        </Button>
      </div>
    </span>
  );
};

export default UserSupport;
