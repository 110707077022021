import React from 'react';


const VideoHome = () => {
    return (
        <div className="videoHome" id="home_section">
            <iframe className="video" src="https://www.youtube.com/embed/GvpC2e5aGVI?autoplay=1&controls=0&loop=1&autoplay=1&mute=1&showinfo=0"  allowFullScreen></iframe>
        </div>
    )
}

export default VideoHome